import { queryGraphQL } from '@/data/apollo';
import { MsrpsRead } from '@/data/management/msrp.graphql';
import { Client, Item, type QueryMsrpsReadArgs } from '@/types/schema';

export const getMSRPPriceForClient = async ( client: Client, item: Item, price?: number ) => {
	const { msrpsRead } = await queryGraphQL<QueryMsrpsReadArgs>( {
		query    : MsrpsRead,
		variables: { options: { limit: 1, filter: { client: client.id, item: item.id } } },
	} );
	return msrpsRead?.items?.length ? msrpsRead.items[ 0 ].value : price;
};
