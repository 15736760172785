import DrawerHandle from '@/components/drawerHandle';
import { SwipeableBottomSheet } from '@/providers/modal/responsiveModal';
import type { Breakpoint, DrawerProps, SwipeableDrawerProps, Theme } from '@mui/material';
import { Drawer, useMediaQuery } from '@mui/material';
import type { ReactNode } from 'react';
import { useState } from 'react';

export default function useResponsiveDrawer(
	content: ( closeDrawer?: () => void ) => ReactNode,
	{ breakpoint = [ 'sm', 'lg' ] }: { breakpoint?: Breakpoint[] } = {},
	props?: Omit<Partial<DrawerProps | SwipeableDrawerProps>, 'onClose'> & {
		onClose?: ( event?, reason? ) => boolean | void
	},
) {
	const medium = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( breakpoint[ 0 ] ) );
	const large = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( breakpoint[ 1 ] ) );
	
	const [ open, setOpen ] = useState( false );
	
	const closeDrawer = ( event?, reason? ) => {
		if ( props?.onClose?.( event, reason ) ) return;
		setOpen( false );
	};
	
	let toggleDrawer = ( bool?: boolean ) => setOpen( ( open ) => bool !== undefined ? bool : !open ), drawer;
	
	if ( large ) {
		toggleDrawer = null;
		drawer = content(); // Because there is no closeDrawer on large screens
	} else if ( medium ) {
		drawer = (
			<Drawer
				closeAfterTransition
				variant='temporary'
				anchor='right'
				open={open}
				{...props}
				onClose={closeDrawer}>
				{content( closeDrawer )}
			</Drawer>
		);
	} else {
		drawer = (
			<SwipeableBottomSheet
				open={open}
				{...props}
				onClose={() => closeDrawer()}
				onOpen={() => null}>
				<DrawerHandle/>
				{content( () => closeDrawer() )}
			</SwipeableBottomSheet>
		);
	}
	
	return { toggleDrawer, drawer };
}
