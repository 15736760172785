import CustomCheckBox from '@/components/customCheckBox';
import InputStepper from '@/components/form/fields/InputStepper';
import currencyFormat from '@/helpers/currencyFormat';
import { LineItem, ModifierBase } from '@/types/schema';
import {
	Box,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Radio,
	Stack,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useFormikContext } from 'formik';

export default function Modifier( {
	type,
	modifier,
	ids,
	isMax,
	removeModifierQuantityPicker,
}: {
	type: 'checkbox' | 'radio',
	modifier: ModifierBase,
	ids: string[],
	isMax: boolean,
	removeModifierQuantityPicker?: boolean
} ) {
	const id = modifier.externalId;
	const formik = useFormikContext<LineItem>();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const total = modifier.min === 0 || !modifier.min
		? ''
		: `min is ${modifier.min}` + ( modifier.max === 0 || !modifier.max ) ? '' : `max is ${modifier.max}`;
	
	const checked = Boolean( formik.values.metadata?.[ id ] );
	
	const handleRadioChange = ( m: ModifierBase ) => {
		formik.setFieldValue( 'metadata', {
			...formik.values.metadata,
			...Object.assign( {}, ...ids.map( ( id ) => ( { [ id ]: null } ) ) ),
			[ m.externalId ]: 1,
		} );
	};
	
	const handleCheckboxChange = ( m: ModifierBase ) => {
		formik.setFieldValue( 'metadata', {
			...formik.values.metadata,
			[ m.externalId ]: !checked && !isMax ? 1 : null,
		} );
	};
	
	return (
		<Box p={.5}>
			<ListItemButton
				disableRipple
				sx={{ py: .5 }}
				onClick={() => type === 'checkbox'
					? handleCheckboxChange( modifier )
					: handleRadioChange( modifier )}>
				<ListItemIcon sx={{ minWidth: 40 }}>
					{type === 'checkbox' && (
						<CustomCheckBox
							disabled={!modifier.externalId}
							checked={checked}
						/>
					)}
					{type === 'radio' && (
						<Radio
							required
							disabled={!modifier.externalId}
							checked={checked}
						/>
					)}
				</ListItemIcon>
				<ListItemText
					primary={(
						<Typography sx={{ cursor: 'pointer' }}>
							{modifier.name}
						</Typography>
					)}
				
				/>
				<Stack direction='row' alignItems='center' zIndex={5}>
					{!removeModifierQuantityPicker && ( checked && !( modifier.min === 1 && modifier.max === 1 ) && !isMobile && (
						<Tooltip title={total}>
							<InputStepper
								name={`metadata.[${id}]`}
								minVal={modifier.min || 0}
								maxVal={modifier.max || 0}
								stackProps={{
									sx: {
										'height'                : 28,
										'.MuiOutlinedInput-root': { width: 55, height: 23 },
									},
								}}
								onClick={( e ) => e.stopPropagation()}
								onChange={( e ) => {
									e.stopPropagation();
									formik.setFieldValue( `metadata.[${id}]`,
										modifier.max && +e.target.value > modifier.max ? modifier.max : +e.target.value );
								}}
								onFocus={( e ) => e.target?.select()}
							/>
						</Tooltip>
					) )}
					<Typography sx={{ width: 90, textAlign: 'right' }}>
						{modifier.value > 0 ? currencyFormat( modifier.value ) : ''}
					</Typography>
				</Stack>
			</ListItemButton>
			{checked && !( modifier.min === 1 && modifier.max === 1 ) && isMobile && (
				<Tooltip title={total}>
					<Box sx={{ width: 126, m: 'auto' }}>
						<InputStepper
							name={`metadata.[${id}]`}
							minVal={modifier.min || 0}
							maxVal={modifier.max || 0}
							stackProps={{
								sx: {
									'height'                : 28,
									'.MuiOutlinedInput-root': { width: 55, height: 23 },
								},
							}}
							onChange={( e ) => {
								formik.setFieldValue( `metadata.[${id}]`,
									modifier.max && +e.target.value > modifier.max ? modifier.max : +e.target.value );
							}}
							onFocus={( e ) => e.target?.select()}
						/>
					</Box>
				</Tooltip>
			)}
		</Box>
	);
}
