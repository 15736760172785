import PageSection from '@/components/page/section';
import { LineItem } from '@/types/schema';
import { UnfoldMore as UnfoldMoreIcon } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty, reduce } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModifierGroup_ from './modifierGroup';

export default function ModifierGroupsSelect( {
	removeModifierQuantityPicker,
}: {
	removeModifierQuantityPicker?: boolean
} ) {
	const formik = useFormikContext<LineItem>();
	const { t } = useTranslation();
	
	const modifierGroups = formik.values.modifierGroups;
	const [ expanded, setExpanded ] = useState<Record<number, boolean>>(
		reduce( modifierGroups, ( obj, { id } ) => {
			obj[ id ] = true;
			return obj;
		}, {} ),
	);
	
	if ( isEmpty( modifierGroups ) ) return null;
	
	return (
		<PageSection
			primary={t( 'common:modifiers' )}
			actions={(
				<Button
					color='primary'
					variant='outlined'
					startIcon={<UnfoldMoreIcon/>}
					onClick={() => {
						const expandedKeys = Object.keys( expanded );
						if ( Object.values( expanded ).every( ( value ) => value ) ) {
							const allCollapsed = expandedKeys.reduce( ( acc, key ) => {
								acc[ key ] = false;
								return acc;
							}, {} );
							setExpanded( allCollapsed );
						} else {
							const allExpanded = expandedKeys.reduce( ( acc, key ) => {
								acc[ key ] = true;
								return acc;
							}, {} );
							setExpanded( allExpanded );
						}
					}}>
					{expanded[ formik.values.modifierGroups[ 0 ].id ] ? 'Collapse All' : 'Expand All'}
				</Button>
			)}
			primaryTypographyProps={{ variant: 'h6' }}>
			<Paper sx={{ overflow: 'hidden' }}>
				{modifierGroups.map( ( modifierGroup ) => (
					<ModifierGroup_
						key={modifierGroup.id}
						removeModifierQuantityPicker={removeModifierQuantityPicker}
						modifierGroup={modifierGroup}
						expanded={expanded[ modifierGroup.id ]}
						setExpanded={( isExpanded ) => setExpanded( ( expanded ) => ( {
							...expanded,
							[ modifierGroup.id ]: isExpanded,
						} ) )}
					/>
				) )}
			</Paper>
		</PageSection>
	);
}

