import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Chip,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineItem, ModifierGroup as ModifierGroupEntity } from '../../../../../types/schema';
import Modifier from './modifier';

export default function ModifierGroup( {
	expanded,
	setExpanded,
	modifierGroup,
	removeModifierQuantityPicker,
}: {
	expanded: boolean,
	setExpanded: ( expanded: boolean ) => void,
	modifierGroup: ModifierGroupEntity,
	removeModifierQuantityPicker?: boolean
} ) {
	const modifierGroupMin = modifierGroup?.min || 0;
	const modifierGroupMax = modifierGroup?.max || 0;
	const formik = useFormikContext<LineItem>();
	const { t } = useTranslation();
	const [ totalSelectedItems, setTotalSelectedItems ] = useState( 0 );
	
	const selectedModifiers = useMemo(
		() => modifierGroup?.modifiers.filter( ( modifier ) => formik.values.metadata[ modifier.externalId ] ),
		[ modifierGroup.modifiers, formik.values.metadata ],
	);
	useEffect( () => {
		let newTotal = 0;
		selectedModifiers.forEach( ( modifier ) => {
			const modifierId = modifier.externalId;
			const modifierCount = formik.values.metadata[ modifierId ];
			newTotal += Number( modifierCount ) || 0;
		} );
		setTotalSelectedItems( newTotal );
	}, [ selectedModifiers, formik.values.metadata ] );
	
	const minMaxText = modifierGroupMin > 0
		? `${t( 'common:select-atleast' )} ${modifierGroupMin}`
		: modifierGroupMax > 1
			? `${t( 'common:select-atmost' )} ${modifierGroupMax}`
			: '';
	
	if ( !modifierGroup.modifiers?.length ) return null;
	
	const modifierIds = modifierGroup.modifiers.map( ( modifier ) => modifier.externalId );
	if ( modifierIds.some( ( id ) => !id ) ) {
		return null;
	}
	
	const modifierType = modifierGroupMin === 1 && modifierGroupMax === 1 ? 'radio' : 'checkbox';
	
	return (
		<Accordion
			defaultExpanded
			expanded={expanded}
			sx={{ border: 0, bgcolor: 'background.default' }}
			onChange={( event, newExpanded ) => setExpanded( newExpanded )}>
			<AccordionSummary
				sx={{ 'bgcolor': 'divider', '.MuiAccordionSummary-content': { my: 0 } }}
				expandIcon={( <ExpandMoreIcon sx={{ color: 'text.primary' }}/> )}>
				<ListItem disablePadding>
					<ListItemText
						primary={modifierGroup.name}
						primaryTypographyProps={{ variant: 'h5' }}
						secondary={minMaxText}
					/>
					<Box pr={1}>
						{modifierGroupMin > 0 ? (
							<Chip
								color='warning'
								variant='alpha'
								label={t( 'common:required' )}
							/>
						) : (
							<Typography sx={{ color: 'success.main' }} fontWeight={500}>
								{t( 'common:optional' )}
							</Typography>
						)}
					</Box>
				</ListItem>
			</AccordionSummary>
			<AccordionDetails sx={{ p: 0 }}>
				<Stack>
					{modifierGroup.modifiers.map( ( modifier ) => (
						<Modifier
							key={modifier.id}
							type={modifierType}
							ids={modifierIds}
							modifier={modifier}
							isMax={modifierGroupMax > 0 && totalSelectedItems >= modifierGroupMax}
							removeModifierQuantityPicker={removeModifierQuantityPicker}
						/>
					) )}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
}
