import { mutateGraphQL } from '@/data/apollo';
import { LineItemsWrite, LineItemWrite } from '@/graphql/lineItem.graphql';
import { getMSRPPriceForClient } from '@/pages/dashboard/commerce/form/lineItemForm/utils';
import {
	type Client,
	Item,
	LineItem,
	type ModifierGroup,
	MutationLineItemWriteArgs,
	Order,
	type Price,
	Purchase,
	Uom,
} from '@/types/schema';
import { isEmpty, omit, pick, round } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export const addMultipleLineItemsToOrder = async ( {
	lineItems,
	orderId,
	purchaseId,
}: {
	lineItems: LineItem[],
	orderId?: string | undefined,
	purchaseId?: string | undefined
} ) => {
	if ( lineItems.length ) {
		const newLineItems: any[] = lineItems.map( ( lineItem, index ) => ( {
			id            : uuidv4(),
			order         : orderId,
			purchase      : purchaseId,
			name          : lineItem.name,
			externalId    : null,
			image         : lineItem.image,
			description   : lineItem.description,
			cost          : lineItem.cost,
			price         : lineItem.price,
			quantity      : lineItem.quantity,
			unit          : lineItem.unit,
			code          : lineItem.code,
			tax           : lineItem.tax,
			orderTax      : lineItem.orderTax,
			metadata      : lineItem.metadata || undefined,
			prices        : lineItem.prices?.map( ( price ) => ( {
				...pick( price, [
					'name',
					'isPercent',
					'value',
					'quantity',
					'metadata',
					'externalId',
				] ),
			} ) ),
			uom           : lineItem.uom?.id || null,
			item          : lineItem.item?.id ?? undefined,
			category      : lineItem.category?.id ?? undefined,
			modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
		} ) );
		const result = await mutateGraphQL( {
			mutation : LineItemsWrite,
			variables: { inputs: newLineItems },
		} );
		if ( result.lineItemsWrite ) {
			return newLineItems;
		}
	}
	return false;
};

export const addLineItemToOrder = async ( {
	lineItem,
	orderId,
	purchaseId,
}: {
	lineItem: LineItem,
	orderId?: string | undefined,
	purchaseId?: string | undefined
} ) => await mutateGraphQL<MutationLineItemWriteArgs>( {
	mutation : LineItemWrite,
	variables: {
		input: {
			order         : orderId,
			purchase      : purchaseId,
			name          : lineItem.name,
			externalId    : null,
			image         : lineItem.image,
			description   : lineItem.description,
			cost          : lineItem.cost,
			price         : lineItem.price,
			quantity      : lineItem.quantity,
			unit          : lineItem.unit,
			code          : lineItem.code,
			tax           : lineItem.tax,
			orderTax      : lineItem.orderTax,
			metadata      : lineItem.metadata || undefined,
			prices        : lineItem.prices?.map( ( price ) => ( {
				...pick( price, [
					'name',
					'isPercent',
					'value',
					'quantity',
					'metadata',
					'externalId',
				] ),
			} ) ),
			uom           : lineItem.uom?.id || null,
			item          : lineItem.item?.id ?? undefined,
			category      : lineItem.category?.id ?? undefined,
			modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
		},
	},
} );

export async function createLineItemThroughItem(
	selectedItem: Item,
	selectedUom?: Uom,
	client?: Client,
	cashDiscount?: number,
	purchase?: Purchase,
	order?: Order,
	receivedQuantity?: number ) {
	let itemTaxes: Price[];
	let itemFeesAndDiscounts: Price[];
	let modifierGroups: ModifierGroup[];
	let orderTax: boolean;
	let tax: number | null;
	
	if ( !purchase ) {
		itemFeesAndDiscounts = selectedItem.metadata?.prices;
		itemTaxes = selectedItem?.taxes?.filter( ( tax ) => tax.metadata?.externalTax && tax.name !== 'Tax (auto)' );
		orderTax = selectedItem.taxable;
		tax = selectedItem.taxable ? null : 0;
		modifierGroups = selectedItem?.modifierGroups;
		if ( !isEmpty( itemTaxes ) ) {
			orderTax = false;
			tax = 0;
		}
	}
	const uom = selectedUom || selectedItem.uoms?.filter( ( uom ) => uom.selected || !uom.removed )?.[ 0 ];
	
	let price: number = uom?.price;
	let originalPrice: number | null = null;
	let lineItemCashDiscount: number | null = null;
	const orderClient = client || order?.client;
	// get client MSRP price
	if ( selectedItem && orderClient ) {
		price = await getMSRPPriceForClient( orderClient as Client, selectedItem, price );
	}
	if ( cashDiscount && !purchase ) {
		const cdValue = cashDiscount ? round( price ? price * cashDiscount : 0, 2 ) : 0;
		const cdPrice = price ? price + cdValue : 0;
		price = cdPrice;
		originalPrice = cdPrice - cdValue;
		lineItemCashDiscount = cdValue;
	}
	
	return {
		name        : selectedItem.name,
		image       : selectedItem.image,
		sequence    : 5,
		uom         : { id: uom?.id, quantity: uom?.quantity, min: uom?.min, max: uom?.max },
		description : selectedItem.description,
		item        : selectedItem,
		quantity    : 1,
		unit        : uom?.name || 'Unit',
		cost        : uom?.cost,
		receivedQuantity,
		price,
		originalPrice,
		cashDiscount: lineItemCashDiscount,
		code        : uom?.code,
		tax,
		sku         : uom?.sku,
		orderTax,
		category    : { id: selectedItem?.categories?.[ 0 ]?.id ?? undefined },
		modifierGroups,
		prices      : [
			...!isEmpty( itemFeesAndDiscounts )
				? itemFeesAndDiscounts.map( ( price ) => ( { ...omit( price, [ 'id' ] ) } ) )
				: [],
			...!isEmpty( itemTaxes ) ? itemTaxes.map( ( tax ) => ( {
				...tax,
				externalId: null,
				metadata  : { ...tax.metadata, externalId: tax.externalId, useTax: !orderClient?.metadata?.exemptFromTax },
				id        : uuidv4(),
			} ) ) : [] ],
	};
	
}
